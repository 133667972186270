import React from 'react';

import { pipelineMasterNode } from '../pdc/chart/chart-layout';
import { PdcDefinitions } from '../pdc/pdc-definitions';
import { CalcPage } from '../sections/calc-page';

const FormPage = () => (
  <CalcPage
    title="Dyrektywa Ciśnieniowa"
    definition={PdcDefinitions.pipeline}
    startingNode={pipelineMasterNode}
  ></CalcPage>
);

export default FormPage;
